<div class="drag-box" cdkDrag [cdkDragDisabled]="cdkDrag">

    <div class="__header">
      <button matRipple class="btn_close" (click)="closeWindow()">
        <mat-icon>close</mat-icon>
      </button>
      <div class="reciver_info">
        <img [src]="isExist(chatData?.receiverDetails?.profile_image) ? imageUrl + 'user/' + chatData?.receiverDetails?.profile_image : 'assets/images/questionic.svg'" alt="">
        <div class="_name" style="margin-top: 2px;">{{chatData?.receiverDetails?.full_name}}<br>
          <h6 *ngIf="!isOnline">Last seen {{getTimeStamp()}}</h6>
        </div>
        <span class="material-icons online" *ngIf="isOnline">
            fiber_manual_record
        </span>
      </div>

    </div>
  
    <div class="chatBody messageList" [class.haspreview]="files?.length > 0">
        <!-- <div class="loader" *ngIf="(messageLists | async)?.length === 0"></div> -->
      <perfect-scrollbar>
        <ng-container *ngIf="(messageLists | async)?.length > 0">
          <ul>
            <ng-container *ngFor="let item of messageLists | async">
              <li [class.persion]="item?.sender_id !== USER?._id" [class.user]="item?.sender_id === USER?._id">
                <div class="messageinneritem">
                  <figure>
                    <ng-container *ngIf="item?.sender_id !== USER?._id">
                      <img [src]="isExist(chatData?.receiverDetails?.profile_image) ? imageUrl + 'user/' + chatData?.receiverDetails?.profile_image : 'assets/images/questionic.svg'" alt="">
                    </ng-container>
                    <ng-container *ngIf="item?.sender_id === USER?._id">
                      <img [src]="isExist(chatData?.senderDetails?.profile_image) ? imageUrl + 'user/' + chatData?.senderDetails?.profile_image : 'assets/images/questionic.svg'" alt="">
                    </ng-container>
                  </figure>
                  <div class="messagecont">
                    <p *ngIf="item?.message?.length > 0">{{item?.message}}</p>
                    <div *ngIf="item?.chatAttachment">
                      <!-- <img *ngFor="let imgs of item?.chatAttachment" [src]="IMAGE_URL + 'chat_media/' + imgs" alt=""> -->
                    </div>
                    <div class="time-tickic">
                      <span> {{item?.time | date: 'shortTime'}}
                        <ng-container *ngIf="item?.sender_id === USER?._id">
                            <mat-icon [ngStyle]="{'color': item?.read ? 'green' : 'gray'}">done_all</mat-icon>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </perfect-scrollbar>
    </div>
    <div class="uploadimgprvwrap" *ngIf="files?.length > 0">
      <div *ngFor='let url of files;let index = index' class="prvimg">
        <img [src]="url.preview" alt="">
        <button matRipple (click)="removeSelectedFile(index)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  
    <div class="messagetypesec">
      <input [(ngModel)]="message" placeholder="Type here... " (keydown.enter)="sendMessage()">
      <div class="action">
        <input style="display: none;" type="file" #fileUpload accept="image/*" multiple
          (change)="handleFileInput($event)">
        <!-- <button matRipple (click)="fileUpload.click()" class="fileupload">
            <mat-icon>attachment</mat-icon>
        </button> -->
        <button matRipple (click)="sendMessage()" type="submit">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
</div>
  