// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // BASE_API_ENDPOINT: 'http://104.211.229.156:1470/api/',
  // BASE_IMAGE_ENDPOINT: 'http://104.211.229.156:1470/uploads/',
  // BASE_API_ENDPOINT: 'https://admin-collaborante.dedicateddevelopers.us/api/',
  // BASE_IMAGE_ENDPOINT: 'https://admin-collaborante.dedicateddevelopers.us/uploads/',

  // BASE_API_ENDPOINT: 'https://api.collaborante.com/api/',
  // BASE_IMAGE_ENDPOINT: 'https://api.collaborante.com/uploads/',

  BASE_API_ENDPOINT: 'https://admin.collaborante.com/api/',
  BASE_IMAGE_ENDPOINT: 'https://admin.collaborante.com/uploads/',
  production: false,

  firebaseConfig: {
    apiKey: "AIzaSyAN4ubFDknHc53nMcAxzfyk3V3MdbU9sQ8",
    authDomain: "collaborante-cec81.firebaseapp.com",
    projectId: "collaborante-cec81",
    storageBucket: "collaborante-cec81.appspot.com",
    messagingSenderId: "964978698860",
    appId: "1:964978698860:web:a2be1ee145d76c2f9d0649",
    measurementId: "G-PBHZHYP6C2",
    databaseURL: "https://collaborante-cec81-default-rtdb.firebaseio.com",


    // apiKey: "AIzaSyCEQvaK7NENzqmDcOv4yl-_5UgHbUR5ook",
    // authDomain: "collaborante-774e1.firebaseapp.com",
    // databaseURL: "https://collaborante-774e1-default-rtdb.firebaseio.com",
    // projectId: "collaborante-774e1",
    // storageBucket: "collaborante-774e1.appspot.com",
    // messagingSenderId: "387173600350",
    // appId: "1:387173600350:web:8ad4b6424c0f5d9d8cb8cf",
    // measurementId: "G-QQD2YFW658"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
