<header class="header" [ngClass]="{'afterHeader': isLoggedIn}">
  <div class="container-fluid headerwrap">
    <div class="row align-items-center">
      <div class="col-auto logosec">
        <a routerLink="/home" (click)="closeMainNav()"><img src="assets/images/fulllogo.png" draggable="false"
            class="user-select-none" alt=""></a>
      </div>
      <div class="col-auto ml-auto">
        <div class="mainHead">
          <ul class="list-unstyled d-lg-flex align-items-center menusec" (click)="closeMainNav()">
            <!--   afterlogin -->
            <ng-container *ngIf="isLoggedIn">
              <li><a  [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" ><span><img draggable="false"
                      class="user-select-none" src="assets/images/attachic.svg" alt="" > <i>RFI</i></span></a></li>
              <li><a  [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" ><span><img draggable="false" class="user-select-none"
                      src="assets/images/attachic.svg" alt=""> <i>RFP</i></span></a></li>
              <li><a  [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" ><span><img draggable="false" class="user-select-none"
                      src="assets/images/attachic.svg" alt=""> <i>RFQ</i></span></a></li>
              
              <li><a *ngIf="user?.role?.role === 'customer'" routerLink="/user/customer/product-overview" routerLinkActive="active"><span><img draggable="false"
                        class="user-select-none" src="assets/images/smileic.svg" alt=""> <i>Product Overview</i></span></a></li>

              <li><a *ngIf="user?.role?.role === 'customer'" routerLink="/user/customer/new-request" routerLinkActive="active"><span> <i>My Request</i></span></a></li>
              <li><a *ngIf="user?.role?.role === 'vendor'" routerLink="/user/vendor/new-request" routerLinkActive="active"><span><i>My Request</i></span></a></li>
                      
              <li><a (click)="logout()"><span>Sign out</span></a></li>
              <!-- <li class="questionmenu"><a  [matMenuTriggerFor]="menu"><span><img draggable="false"
                      class="user-select-none" src="assets/images/questionic.svg" alt=""></span></a></li> -->

        <!-- this.profilePicPreview = user.profile_image !== '' ? user.register_type === 'google' ? res.profile_image : this.imageUrl + 'user/' + user.profile_image : 'assets/images/questionic.svg'; -->

                      <!-- <li class="questionmenu"><a [matMenuTriggerFor]="menu"><span><img draggable="false"
                        class="user-select-none" [src]="user?.profile_image !== '' ? user?.register_type !== 'normal' ? user?.profile_image : this.imageUrl + 'user/' + user?.profile_image : 'assets/images/questionic.svg'" alt=""></span></a></li> -->
                      <!-- routerLink="#" routerLinkActive="active" -->
            </ng-container>
            <mat-menu #menu="matMenu">
              <button mat-menu-item routerLink="/user/profile">Profile</button>
              <button *ngIf="user?.register_type === 'normal'" mat-menu-item routerLink="/user/change-password">Change Password</button>
              <!-- <button mat-menu-item *ngIf="user?.role?.role === 'vendor'" routerLink="/user/vendor/upload-default-answer">Upload Default answer</button>
              <button mat-menu-item *ngIf="user?.role?.role === 'vendor'" routerLink="/user/vendor/vendor-default">Set Default answer</button>
              <button mat-menu-item  routerLink="/user/rating">Rating</button>
              <button mat-menu-item  routerLink="/user/generate-report">Generate Report</button> -->
              <button mat-menu-item  routerLink="/user/contact-us">Contact Us</button>
              <button mat-menu-item  routerLink="/faqs">FAQ</button>
              <!-- <button mat-menu-item *ngIf="user?.role?.role === 'vendor'" routerLink="/user/vendor/new-request">New Request</button> -->
            </mat-menu>
            <!-- Before login -->
            <ng-container *ngIf="!isLoggedIn">
              <li><a routerLink="/register" routerLinkActive="active"><span><img draggable="false"
                      class="user-select-none" src="assets/images/icon1.svg" alt=""> <i>Sign Up</i></span></a></li>
              <li><a routerLink="/customer-login" routerLinkActive="active"><span><img draggable="false" class="user-select-none"
                      src="assets/images/icon2.svg" alt=""> <i>Sign In As Customer</i></span></a></li>
              <li><a routerLink="/vendor-login" routerLinkActive="active"><span><img draggable="false" class="user-select-none"
                      src="assets/images/icon2.svg" alt=""> <i>Sign In As Vendor</i></span></a></li>

            </ng-container>
          </ul>
        </div>
        <div class="mainHeadBtn d-lg-none">
          <button (click)="sideNavOPen()" class="btn shadow-none " matRipple>
            <i class="material-icons align-middle">
              menu
            </i></button>
        </div>
      </div>
    </div>
  </div>
</header>



<div class="d-none">
  <mat-toolbar class="d-none">
    <div class=" full-width flex middle" [class.justify]="isLoggedIn">
      <a mat-menu-item [routerLink]="['/home']" routerLinkActive="active">HOME</a>
      <ng-container *ngIf="!isLoggedIn">
        <a mat-menu-item [routerLink]="['/login']" routerLinkActive="active">LOGIN</a>
        <a mat-menu-item [routerLink]="['/register']" routerLinkActive="active">REGISTER</a>
      </ng-container>
      <ng-container *ngIf="isLoggedIn">
        <div class="flex middle loggedBox" [matMenuTriggerFor]="loggedMenu">
          <div class="userimg flex center middle pxwidth-40">
            <mat-icon>person</mat-icon>
          </div>
          <span class="pxwidth-10"></span>
          <div class="name">{{username}}</div>
          <span class="pxwidth-10"></span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </ng-container>
    </div>
  </mat-toolbar>
  <mat-menu #loggedMenu="matMenu" class="pxwidth-200">
    <a mat-menu-item [routerLink]="['/user/dashboard']" routerLinkActive="active">USER</a>
    <a mat-menu-item [routerLink]="['/user/profile']" routerLinkActive="active">PROFILE</a>
    <a mat-menu-item (click)="logout()">LOGOUT</a>
  </mat-menu>

</div>
