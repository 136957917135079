import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, takeUntil } from 'rxjs/operators';
import * as firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private unreadMessages = new  BehaviorSubject(0);
  unreadMessagesCount = this.unreadMessages.asObservable();
  constructor(
    private _angularFireDatabase: AngularFireDatabase,
    private firestorage: AngularFireStorage,
  ) {
  }
  
  changeUnreadMessagesCount(message) {
    this.unreadMessages.next(message)
  }


  // getMessage(KEY) {
  //   let data = [];
  //   return this._angularFireDatabase.list(`/user_chat/${KEY}`).snapshotChanges().pipe(map(res => {
  //     data = [];
  //     res.forEach(el => {
  //       if (el.payload.key !== 'isTyping') {
  //         const m = el.payload.val();
  //         data.push(m);
  //       }
  //     });
  //     return data;
  //   }));
  // }

  getMessage(KEY) {
    let data = [];
    return this._angularFireDatabase.list(`/user_chat/${KEY}`).snapshotChanges().pipe(map((res: any) => {
      data = [];
      res.forEach((el: any) => {
        if (el.payload.key !== 'isTyping') {
          const m = el.payload.val();
          data.push(m);
        }
      });
      return data;
    }));

  }

  getIsOnline() {
    const data = [];
    return this._angularFireDatabase.list(`/user_chat/`).snapshotChanges().pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }));
  }

  // sendMessage(data, KEY: string) {
  //   return this._angularFireDatabase.list(`/user_chat/${KEY}`).push(data);
  // }

  sendMessage(data, KEY: string) {
    return this._angularFireDatabase.list(`/user_chat/${KEY}`).push(data);
  }

  sendReport(data, KEY: string) {
    return this._angularFireDatabase.list(`/user_chat/${KEY}`).push(data);
  }


  // updateTyping(KEY, Value) {
  //   return this._angularFireDatabase.object(`/user_chat/${KEY}/isTyping`).update(Value);
  // }

  updateTyping(KEY, Value) {
    return this._angularFireDatabase.object(`/user_chat/${KEY}/isTyping`).update(Value);
  }

  updateOnlineStatus(KEY, Value) {
    return this._angularFireDatabase.object(`/user_chat/${KEY}`).update(Value);
  }

  setTyping(KEY, Value) {
    return this._angularFireDatabase.object(`/user_chat/${KEY}/isTyping`).set(Value);
  }

  getTyping(KEY) {
    let data = [];
    return this._angularFireDatabase.list(`/user_chat/${KEY}`).snapshotChanges().pipe(map((res: any) => {
      data = [];
      res.forEach((el: any) => {
        if (el.payload.key === 'isTyping') {
          const m = el.payload.val();
          data.push(m);
        }
      });
      return data[0];
    }));
  }





  getUserList() {
    // let data = [];
    return this._angularFireDatabase.list(`/user_chat`).snapshotChanges().pipe(map((res: any) => {
      const data = [];
      res.forEach((el: any) => {
        if (el.payload.key !== 'isTyping') {

          const m = el.payload.val();
          const chatTemp = {
            id: el.payload.key,
            details: m
          };
          data.push(chatTemp);
        }
      });
      return data;
    }));
  }

  notifyNewMessage() {
    return this._angularFireDatabase.list('/user_chat/').snapshotChanges().pipe(map((res: any) => {
      const data = [];
      res.forEach((el: any) => {
        const m = el.payload.val();
        const chatTemp = {
          id: el.payload.key,
          details: m
        };
        data.push(chatTemp);
      });
      return data;
    }));
  }

  removeMessage(KEY, chatId) {
    return this._angularFireDatabase.object(`/user_chat/${KEY}/${chatId}`).remove();
  }

  // async  pushUpload(upload, type, key) {
  //   const date = new Date();
  //   const storageRef = firebase.storage().ref(type);
  //   const uploadTask = storageRef.child(key).child(date.toString()).put(upload);
  //   let url;
  //   let err;
  //   await uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
  //     (snapshot) => {
  //       upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     },
  //     (error) => {
  //       err = error;
  //       url = '';
  //     },
  //     () => {
  //       url = uploadTask.snapshot.ref.getDownloadURL();
  //     }
  //   );

  //   return new Promise((resolve, reject) => {
  //     if (url) {
  //       resolve(url);
  //     } else {
  //       reject(err);
  //     }
  //   });
  // }


  uploadFileToFirebase(token, file) {
    const date = new Date() + '';
    let refURL;
    const path = `${token}/${date.toString()}`;
    return new Promise((resolve, reject) => {
      this.firestorage.upload(`${path}`, file).then(res => {
        res.ref.getDownloadURL().then(URL => {
          refURL = URL;
          resolve(refURL);
        });
      }).catch(err => {
        reject(err);
      });
    });
  }




  updateAsRead(USERID: string, TOKEN: string, destroyer: any) {
    this._angularFireDatabase.list(`/user_chat/${TOKEN}`).snapshotChanges()
      .pipe(takeUntil(destroyer))
      .subscribe((result: Array<any>) => {
        result.forEach((item) => {
          if (item.payload.key !== 'is_typing') {
            const element = item.payload.val();
            if (element.sender_id !== USERID) {
              const obj = this._angularFireDatabase.object(`/user_chat/${TOKEN}/${item.payload.key}`);
              obj.update({ read: true });
            }
          }
        });
      });
  }

}
