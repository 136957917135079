import { Component, Input, NgZone, OnInit, Output, ViewChild, EventEmitter, AfterContentInit, OnChanges, SimpleChanges, AfterViewInit, HostListener } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { isUndefined } from 'util';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {
  destroyer: Subject<boolean> = new Subject<boolean>();
  @Input() reciverInfo: any;
  @Output() closePopup = new EventEmitter();
  imageUrl: string;
  files: any;
  @Input() chatData: any;
  @ViewChild(PerfectScrollbarComponent, { static: true }) public directiveScroll: PerfectScrollbarComponent;
  USER: any;
  messageLists: Observable<any[]>;
  cdkDrag: any;
  key: any;
  isOnline: any;
  
  message: any;
  userId: any;
  chatToken: any;
  lastSeenTime: any;

  constructor(
    private firebase: FirebaseService,
    private storage: StorageService,
    private api: ApiService,
    private ngZone: NgZone,
  ) {
    this.messageLists = new Observable(undefined);
    this.files = [];
    this.imageUrl = environment.BASE_IMAGE_ENDPOINT;
    this.USER = this.storage.getUser();
    this.goOnline();
  }

  ngOnInit(): void {
    this.chatToken= this.chatData.chat_token;
    if (this.chatToken) {
      this.getMessage(this.chatToken).then(() => {
        setTimeout(async () => {
          if (this.directiveScroll instanceof PerfectScrollbarComponent) {
            await this.firebase.updateAsRead(this.USER._id, this.chatToken, this.destroyer);
          }
        }, 100);
        this.getStatus();
      });
    }
    if (window.screen.width < 769) {
      this.cdkDrag = true;
    } else {
      this.cdkDrag = false;
    }
  }

  isExist(val) {
    if (_.isNull(val) || _.isEmpty(val)) {
      return false;
    } else {
      return true;
    }
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      const type = event.target.files[0].type.split('/');
      const maxFileSupport = 5;

      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].type !== 'image/svg+xml') {
          if (i <= (maxFileSupport - 1) - this.files.length) {
            const reader = new FileReader();
            reader.onload = (ev: any) => {
              if (ev.target.result.split('/')[0].split(':')[1] === 'image') {
                this.files.push({
                  preview: ev.target.result,
                  file: event.target.files[i]
                });
              } else {
                this.api.alert('Invalid File Format', 'error');
              }
            };
            reader.readAsDataURL(event.target.files[i]);
          } else {
            this.api.alert('Maximum 5 files are supported', 'warning');
          }
        } else {
          this.api.alert('Invalid File Format', 'error');
        }
      }
    }
  }

  // this.files[i].file
  removeSelectedFile(index) {
    this.files.splice(index, 1);
  }

  async getFormData(val, msg, TOKEN) {
    const formData = new FormData();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < val.length; i++) {
      formData.append(`chat_media[${i}]`, val[i].file);
    }
    // this.api.postMultiDataWithToken(`chat/media/upload`, formData)
    //   .subscribe(async (res: any) => {
    //     if (res.status === 200) {
    //       const data = {
    //         time: new Date().toString(),
    //         message: msg.value,
    //         sender_id: this.USER._id,
    //         // receiver_id: this.selectedUser._id,
    //         read: false,
    //         chatAttachment: []
    //       };
    //       // tslint:disable-next-line:prefer-for-of
    //       for (let index = 0; index < res.data.length; index++) {
    //         data.chatAttachment.push(res.data[index].file_name);
    //       }

    //       await this.firebase.sendMessage(TOKEN, data).then(() => {
    //         this.files = [];
    //         msg.value = '';
    //         setTimeout(async () => {
    //           if (this.directiveScroll instanceof PerfectScrollbarComponent) {
    //             await this.directiveScroll.directiveRef.scrollToBottom(0, 100);
    //           }
    //         }, 100);
    //       });
    //     } else {
    //       this.api.alert(res.message, 'error');
    //     }
    //   }, err => {
    //     this.api.alert(err.message, 'error');
    //   });
  }
  async getMessage(token: any) {
    this.messageLists = await this.firebase.getMessage(token);
    this.firebase.getMessage(token).subscribe((res:any)=>{
      this.scrollToBottom();
    })
  
    // this.messageLists.subscribe((res)=>{
    // })
    // this.firebase.getMessage(this.key).pipe(takeUntil(this.destroyer)).subscribe((res) => {
    //   if (res) {
    //     this.messageLists = res;
    //     this.getStatus();
    //   }
    // });
  }

 

  // updateAsRead() {
  //   this.firebase.updateAsRead(this.key, this.storage.getLoggedInRole()._id).pipe(takeUntil(this.destroyer)).subscribe((res) => {
  //     // this.updateRead.emit(true);
  //     // this.getChatUserList();
  //   });
  // }

  scrollToBottom(): void {
    if (isUndefined(this.directiveScroll)) { return; }
    this.directiveScroll.directiveRef.update();
    setTimeout(() => {
      this.directiveScroll.directiveRef.scrollToBottom(0, 100);
    }, 200);
  }

  sendMessage() {
    if (this.message.trim().length === 0) {
    } else {
      // this.firebase.uploadFileToFirebase(this.key, this.files).then(res => {
      //   if(res){

      //   }
      // });
      const data = {
        message: this.message.trim(),
        time: new Date() + '',
        sender_id: this.chatData.sender_id,
        receiver_id: this.chatData.receiver_id,
        read: false
      };

      this.firebase.sendMessage(data, this.chatToken).then(() => {
        this.message = '';
      });
    }
  }

  getStatus() {
    this.firebase.getIsOnline().pipe(takeUntil(this.destroyer)).subscribe((res: any) => {
      res.forEach((item: any) => {
        if (this.chatData.receiver_id === item.key) {
          const data = item.payload.val();
          this.isOnline = data.isOnline;
          this.lastSeenTime = data.last_logged_in;
        }
      });
    });
    //this.directiveScroll.directiveRef.scrollToBottom(0, 100)
  }

  goOffline() {
    const temp = {
      isOnline: false,
      last_logged_in: new Date() + ''
    };
    this.firebase.updateOnlineStatus(this.USER._id, temp);
  }
  goOnline() {
    const temp = {
      isOnline: true,
    };
    this.firebase.updateOnlineStatus(this.USER._id, temp);
  }


  getTimeStamp() {
    let time;
    this.ngZone.runOutsideAngular(() => {
      time = moment(this.lastSeenTime).fromNow();
    });
    return time;
  }

  typing() {
  }

  closeWindow() {
    this.closePopup.emit('close');
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    this.goOffline();
 }
 
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.unsubscribe();
    this.goOffline();
  }

}
