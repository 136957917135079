<footer>
  <div class="footer" #footer>
  	<div class="container">
 	 <!-- <p>Copyright © {{today?.getFullYear()}} <span>Collaborante</span>. All Rights Reserved.</p> -->
	  <div class="d-flex justify-content-center">
		  <button class="btn"><i class="fa-brands fa-twitter"></i></button>
		  <button class="btn"><i class="fa-brands fa-linkedin-in"></i></button>
	  </div>
 	</div>
  </div>

</footer>
