import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-top-tabs',
  templateUrl: './top-tabs.component.html',
  styleUrls: ['./top-tabs.component.scss']
})
export class TopTabsComponent implements OnInit {
  requirementList: any;
  @Input() selected:any
  @Output() requirement = new EventEmitter();

    constructor(
      private apiservice: ApiService
    ) { }
  
    ngOnInit(): void {
      this.getRequirementList();
    }

    getRequirementList() {
      this.apiservice.get('requirement/type/list').subscribe((res: any)=>{
        // if (res) {
          this.requirementList=res.data;
          this.selected = this.requirementList[0];
          this.requirement.emit(this.selected)
        // } else {
        //   this.apiservice.alert(res.message, 'error');
        // }
      }, err=>{
        this.apiservice.alert('Something went wrong', 'error');
      });
    }
    selectItem(item) {
      this.selected = item;
      this.requirement.emit(item)
    }
  }
  