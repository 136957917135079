import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ApiService } from 'src/app/services/api.service';
import { EventService } from 'src/app/services/event.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  step = 0;
  categoryList: any;
  productList: any;
  productData: any;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  @Output() productDataEvent: EventEmitter<any> = new EventEmitter();
  vendorList: any;
  
  constructor(
    private apiservice: ApiService,
    public storage: StorageService,
  ) {
   }

  ngOnInit(): void {
    this.getCategoryList();
  }


  getCategoryList() {
    let url;
    if(this.storage?.getUser()?.role === 'vendor') {
      url =`category/vendor/list`;
    }
    else {
      url =`category/list`;
    }
    this.apiservice.get(url).subscribe((res: any) => {
        // if (res) {
          this.categoryList = res.data;
          if(this.storage.getRequestData()) {
            this.productData = this.storage.getRequestData().product;
          } else {
            this.productData = this.categoryList[0].products.length > 0 ? this.categoryList[0].products[0] : '';
          }
          this.productDataEvent.emit(this.productData);
          this.accordion.openAll();
        // } else {
        //   this.apiservice.alert(res.message, 'error');
        // }
    }, err => {
      this.apiservice.alert('Something went wrong! Please try again', 'error');
    });
  }



setProductData(value: string) {
  
}

selectProductData(product, i, j) {
  // this.getVendorList(product._id);
  this.productData =  this.categoryList[i].products[j];
  this.productDataEvent.emit(this.productData);
}

  sideNavOPen(){
       // window.scroll(0,0);
       window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    document.querySelector('#techSide').classList.toggle('open');
    document.querySelector('body').classList.toggle('body-hidden');
    document.querySelector('.mainHead').classList.remove('open');
    document.querySelector('body').classList.remove('modal-open');
  }

}