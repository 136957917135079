import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean;
  username: string;
  login = "notLogin";
  user: any;
  role: any;
  imageUrl: any;
  constructor(
    private apiservice:ApiService,
    private event: EventService,
    public storage: StorageService,
    private route: Router,
  ) {
    //console.log('UserDetials:',this.storage.getUser());
    this.imageUrl = environment.BASE_IMAGE_ENDPOINT;
    //  This is just a temporary  Login(check) Function
    // this.event.isLogin.subscribe((res: boolean) => {
    //   this.isLoggedIn = res;
    //   this.isLoggedIn = this.storage.getUser();
    // });

  }

  ngOnInit(): void {
    this.event.isLogin.subscribe((res: boolean) => {
      // this.isLoggedIn = res;
      this.isLoggedIn = this.storage.getUser();
      if (res) {
        this.getProfile();
      this.username = this.storage.getDataField('username');
      }
    });

  this.event.profileUpdate.subscribe((res: any) => {
    if (res) {
      this.getProfile();
      }
    });
}

  getProfile() {
      this.apiservice.get('user/getprofile').subscribe((res: any)=>{
          if (res.status === 200) {
            this.user = res.data;
          } else {
            this.apiservice.alert(res.message, 'error');
          }
      },err=>{
        this.apiservice.alert('Something Went Wrong', 'error');
      });
  }
  
  sideNavOPen() {
      // window.scroll(0,0);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    //console.log('querySelector:',document.querySelector('body')?.classList);
    document.querySelector('body')?.classList.remove('body-hidden');
    document.querySelector('body')?.classList.toggle('modal-open');
    document.querySelector('body')?.classList.remove('modal-open');
    document.querySelector('.mainHead')?.classList.toggle('open');
    document.querySelector('#techSide')?.classList.remove('open');

  }
  closeMainNav() {
    //console.log('ClassList:', document.querySelector('body').classList)
    let body = document.querySelector('body');
    let classNames = ['modal-open', 'body-hidden'];
    classNames.forEach((items)=> {
      return body.classList.remove(items);
    })
    
    // document.querySelector('.mainHead').classList.remove('open');
    // document.querySelector('#techSide').classList.remove('open');
    // document.querySelector('body')?.classList.remove('modal-open');
    // document.querySelector('body')?.classList.remove('overflow-hidden');
  }

  //  This is just a temporary  Logout Function
  logout() {
    this.storage.clearUser();
    this.storage.clearRequestData();
    this.storage.setUser(false);
    this.event.setLoginEmmit(false);
    this.route.navigate(['/home']);
  }
}
