<div class="d-xl-none mobileSideNavBtn">
  <div class="row justify-content-between align-items-center">
    <div class="col-auto">
      <h6 class="mb-0">
        <b>My Requests</b>
      </h6>
    </div>
    <div class="col-auto">
      <button (click)="sideNavOPen()" class="btn shadow-none " matRipple>
        <i class="material-icons align-middle">
          more_vert
        </i></button>
    </div>
  </div>
</div>
<div class="aside-bg">
<aside class="sideNavigation d-flex flex-column" id="techSide">
  <h5 class="d-flex align-items-center justify-content-between mb-2 pageHead  text-white p-2 p-xxxl-4 ">
    <span>My Requests </span>
    <!-- 
      <i class="material-icons">
        keyboard_arrow_down
      </i> -->
  </h5>
  <div class="w-100  overflow-auto bg-white panelMenuBox">
    <ul class="sidebarMenu">
      <!-- <li><a [routerLink]="['/user', storage.getUser()?.role, 'new-request']" routerLinkActive="active">My Requests</a>
      </li> -->
      <li *ngIf="storage.getUser()?.role === 'customer'" [routerLink]="['/user', 'steps']" routerLinkActive="active">
        <a>Create New Requests</a></li>
      <!-- <li *ngIf="storage.getUser()?.role === 'customer'" routerLinkActive="active"><a>Update Existing Requests</a></li> -->
      <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']">RFI</a></li>
      <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']">RFP</a></li>
      <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']">RFQ</a></li>
    </ul>
  </div>

</aside>
<aside class="sideNavigation d-flex flex-column mt-2" id="techSide">
  <h5 class="d-flex align-items-center justify-content-between mb-2 pageHead  text-white p-2 p-xxxl-4 ">
    <span>Requirements Admin </span>
  </h5>
  <div class="w-100  overflow-auto bg-white panelMenuBox">
    <ul class="sidebarMenu">
      <li *ngIf="storage?.getUser()?.role === 'vendor'"><a routerLink="/user/vendor/upload-default-answer"
          routerLinkActive="active">Upload Default answer</a></li>
      <li *ngIf="storage?.getUser()?.role === 'vendor'"><a routerLink="/user/vendor/vendor-default"
          routerLinkActive="active">Set Default answer</a></li>
      <li><a routerLink="/user/rating" routerLinkActive="active">Rating</a></li>
      <li><a routerLink="/user/generate-report">Generate Report</a></li>
      <li><a routerLink="/user/chat">Message</a></li>
      <!-- <li *ngIf="storage.getUser()?.role === 'customer'" routerLinkActive="active"><a>Update Existing Requests</a></li> -->
      <!-- <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFI</a></li>
        <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFP</a></li>
        <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFQ</a></li> -->
    </ul>
  </div>

</aside>
</div>