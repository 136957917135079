import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopTabsComponent } from './top-tabs/top-tabs.component';
import { SideBarMenuForTechnologyComponent } from './side-bar-menu-for-technology/side-bar-menu-for-technology.component';
import { MyRequestSideBarMenuComponent } from './my-request-side-bar-menu/my-request-side-bar-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './phone-mask.directive';
import { TopproductComponent } from './topproduct/topproduct.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { CKEditorModule } from 'ckeditor4-angular';



@NgModule({
  declarations: 
  [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TopTabsComponent,
    SideBarMenuForTechnologyComponent,
    MyRequestSideBarMenuComponent,
    PhoneMaskDirective,
    TopproductComponent,
    ChatBoxComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TopTabsComponent,
    SideBarMenuForTechnologyComponent,
    MyRequestSideBarMenuComponent,
    RouterModule,
    PhoneMaskDirective,
    TopproductComponent,
    ChatBoxComponent,
    CKEditorModule,
    PerfectScrollbarModule
  ],
 
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    LazyLoadImageModule,
    PerfectScrollbarModule,
    CKEditorModule,

  ]
})
export class SharedModule { }
