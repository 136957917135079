import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-side-bar-menu-for-technology',
  templateUrl: './side-bar-menu-for-technology.component.html',
  styleUrls: ['./side-bar-menu-for-technology.component.scss']
})
export class SideBarMenuForTechnologyComponent implements OnInit, OnDestroy {
  step = 0;
  categoryList: any;
  productList: any;
  @Input() productData: any;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  @Output() productDataEvent: EventEmitter<any> = new EventEmitter();
  @Output() initialProduct: EventEmitter<any> = new EventEmitter();
  vendorList: any;

  constructor(
    private apiservice: ApiService,
    public storage: StorageService,
    public router: Router
  ) {
   }
  // ngAfterContentInit(): void {
  // }
  // ngAfterViewInit(): void {
  // }

  ngOnInit(): void {
    this.getCategoryList();
  }
  getCategoryList() {
    // tslint:disable-next-line: deprecation
    this.apiservice.get('category/list').subscribe((res: any) => {
        // if (res) {
          this.categoryList = res.data;
          if (this.storage.getRequestData()?.product) {
            this.productData = this.storage.getRequestData().product;
          } else {
            this.productData = this.categoryList[0].products.length > 0 ? this.categoryList[0].products[0] : '';
          }
          this.initialProduct.emit(this.productData);
          this.accordion.openAll();
        // } else {
        //   this.apiservice.alert(res.message, 'error');
        // }
    }, err => {
      this.apiservice.alert('Something went wrong! Please try again', 'error');
    });
  }



setProductData(value: string) {

}

selectProductData(product, i, j) {
  // /user/customer/select-product
  // /user/customer/select-requirements
  // tslint:disable-next-line: max-line-length
  if (this.router.url === '/user/customer/select-product' || this.router.url === '/user/customer/select-requirements' || this.router.url.includes(`product-overview`)) {
    this.productData =  this.categoryList[i].products[j];
    this.productDataEvent.emit(this.productData);
  } else if (this.router.url.includes('user/vendor/questionaire/')) {
   this.router.navigateByUrl(`/user/vendor/new-request/${product._id}`);
  } else {
    this.router.navigateByUrl('/user/customer/select-product');
  }

}

  sideNavOPen() {
       // window.scroll(0,0);
       window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });


       document.querySelector('#techSide').classList.toggle('open');
       document.querySelector('body').classList.toggle('body-hidden');
       document.querySelector('.mainHead').classList.remove('open');
       document.querySelector('body').classList.remove('modal-open');
  }

  ngOnDestroy() {
  }

}
