<div class="d-xl-none mobileSideNavBtn">
  <div class="row justify-content-between align-items-center">
    <div class="col-md-10">
      <!-- <h6 class="mb-0">
        
      </h6> -->
      <p><b>Select Technology</b></p>
    </div>
    <div class="col-md-2">
       <!-- <button class="btn shadow-none " matRipple>
       </button> -->
       <i class="material-icons align-middle" (click)="sideNavOPen()" >
        more_vert
       </i>
    </div>
  </div>
</div>
<aside class="sideNavigation d-flex flex-column " id="techSide">
  <h5 class="d-flex align-items-center justify-content-between mb-2 pageHead bg-tc2 text-white p-2 p-xxxl-4 ">
    <span>Select Technology</span>
    <i class="material-icons">
      keyboard_arrow_down
    </i>
  </h5>


  <div class="w-100 h-100 overflow-auto bg-white panelMenuBox">
    <mat-accordion class="" multi #accordion="matAccordion">
      <ng-container *ngFor="let item of categoryList;let i = index;">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{item?.title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <div class="w-100">
            <ul class="list-unstyled">
            <ng-container *ngFor="let product of item?.products; let j = index;">
              <!-- [style.display]="storage.isVendorsProduct(product) > -1 ? 'block' : 'none'" -->
              <li (click)="selectProductData(product, i, j)">
                <a [ngClass]="{'selected': productData?._id === product?._id}">{{product?.title}}</a>
              </li>
            </ng-container>
            <ng-container *ngIf="item?.products?.length === 0">
              <li>
                No product found.
              </li>
            </ng-container>
            </ul>
            <!-- <div>
              <button matRipple class="btn btn-lg btn-secondary shadow-none btn-block rounded-0">
                <img src="assets/images/icon8.svg" alt="">
              </button>
            </div> -->
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </ng-container>
    </mat-accordion>
  </div>
</aside>
<aside class="sideNavigation d-flex flex-column mt-2" id="techSide">
  <h5 class="d-flex align-items-center justify-content-between mb-2 pageHead bg-tc2 text-white p-2 p-xxxl-4 ">
    <span>Requirements Admin </span>
  </h5>
  <div class="w-100  overflow-auto bg-white panelMenuBox">
    <ul class="sidebarMenu">
      <li *ngIf="storage?.getUser()?.role === 'vendor'"><a routerLink="/user/vendor/upload-default-answer"
          routerLinkActive="active">Upload Default answer</a></li>
      <li *ngIf="storage?.getUser()?.role === 'vendor'"><a routerLink="/user/vendor/vendor-default"
          routerLinkActive="active">Set Default answer</a></li>
      <li><a routerLink="/user/rating" routerLinkActive="active">Rating</a></li>
      <li><a routerLink="/user/generate-report">Generate Report</a></li>
      <li><a routerLink="/user/chat">Message</a></li>
      <!-- <li *ngIf="storage.getUser()?.role === 'customer'" routerLinkActive="active"><a>Update Existing Requests</a></li> -->
      <!-- <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFI</a></li>
        <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFP</a></li>
        <li><a [routerLink]="['/user', storage?.getUser()?.role, 'new-request']" >RFQ</a></li> -->
    </ul>
  </div>

</aside>