import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  TEMP = {
    KEY: '_rfx_auth_1',
    PASSWORD: '90590348534YYIU!@00'
  };
  USER = {
    KEY: '_rfx_auth_0',
    PASSWORD: '!##0895*()?:}95047834&&tes'
  };

  public excelExtensions = ['.csv', '.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.docx', '.doc'];

  constructor(
    private cookie: CookieService
  ) { }



  isVendorsProduct(product) {
    return this.getUser().role === 'vendor' ? [...this.getUser().vendor_products].indexOf(product?._id) : 0;
  }

  private encription(data: any, secret: string) {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret);
  }

  private decription(data: any, secret: string) {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  setTempData(ID: string) {
    return this.cookie.set(this.TEMP.KEY, this.encription(ID, this.TEMP.PASSWORD).toString());
  }
  getTempData() {
    const DATA = this.cookie.get(this.TEMP.KEY) !== null ? this.cookie.get(this.TEMP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }
  clearTempData() {
    return this.cookie.delete(this.USER.KEY);
  }

  setUser(data: any) {
    return this.cookie.set(this.USER.KEY, this.encription(data, this.USER.PASSWORD).toString());
  }
  getUser() {
    const DATA = this.cookie.get(this.USER.KEY) !== null ? this.cookie.get(this.USER.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser() {
    return this.cookie.delete(this.USER.KEY);
  }
  // TOKEN
  getDataField(type: string) {
    if (this.getUser() !== undefined && this.getUser()[type] !== undefined) {
      return this.getUser()[type];
    } else {
      return undefined;
    }
  }

  isAuthenticate() {
    if (this.getDataField('token') !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  setRequestData(data: any) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('REQUESTDATA', this.encription(data, 'REQUESTDATA').toString());
      resolve('');
    });
  }
  getRequestData() {
    const DATA = sessionStorage.getItem('REQUESTDATA') !== null ? sessionStorage.getItem('REQUESTDATA') : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, 'REQUESTDATA');
    } else {
      return undefined;
    }
  }

  getRequestStepsData() {
    return new Promise((resolve, reject) => {
      const DATA = sessionStorage.getItem('REQUESTDATA') !== null ? sessionStorage.getItem('REQUESTDATA') : undefined;
      if (DATA && DATA !== undefined) {
        resolve(this.decription(DATA, 'REQUESTDATA'));
      }
    });
  }

  clearRequestData() {
    return sessionStorage.removeItem('REQUESTDATA');
  }

  setRequestDisable(data: any) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('RequestDisable', JSON.stringify(data));
      resolve('');
    });
  }

  getRequestDisable() {
    return JSON.parse(sessionStorage.getItem('RequestDisable'));
  }

  clearRequestDisable() {
    return sessionStorage.removeItem('RequestDisable');
  }

  setVendorRating(data: any) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('setVendorRating', JSON.stringify(data));
      resolve('');
    });
  }

  getVendorRating() {
    return JSON.parse(sessionStorage.getItem('setVendorRating'));
  }

  clearVendorRating() {
    return sessionStorage.removeItem('setVendorRating');
  }

  setRequirements(key, data) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem(key, JSON.stringify(data));
      resolve('');
    });
  }

  getRequirements(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  clearRequirements(key) {
    return sessionStorage.removeItem(key);
  }


}
