import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-my-request-side-bar-menu',
  templateUrl: './my-request-side-bar-menu.component.html',
  styleUrls: ['./my-request-side-bar-menu.component.scss']
})
export class MyRequestSideBarMenuComponent implements OnInit {

  constructor(
    public storage: StorageService,
  ) {
   }

  ngOnInit(): void {
  }
  sideNavOPen() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    document.querySelector('#techSide').classList.toggle('open');
    document.querySelector('body').classList.toggle('body-hidden');
    document.querySelector('.mainHead').classList.remove('open');
    document.querySelector('body').classList.remove('modal-open');
  }
}
