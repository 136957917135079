import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-topproduct',
  templateUrl: './topproduct.component.html',
  styleUrls: ['./topproduct.component.scss']
})
export class TopproductComponent implements OnInit {
  @Input() vendor :any;
  @Output() product = new EventEmitter<any>();
  selected:any;
  productsList=[];
  constructor(
    private apiservice: ApiService,
    public storage: StorageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    this.apiservice.get('user/getprofile').subscribe((res: any)=>{
        if (res.status === 200) {
          this.productsList = [...res.data.vendor_products_details];
          let data = this.productsList.filter(item=> item?._id === this.route.snapshot.paramMap.get('id'));
          if(data.length === 1){
            [this.selected] = data;
          }
          else{
            this.selected = this.productsList[0];
          }
          this.product.emit(this.selected);
        } else {
          this.apiservice.alert(res.message, 'error');
        }
    },err=>{
      this.apiservice.alert('Something Went Wrong', 'error');
    });
}



}
